import * as Yup from 'yup';
import { validateSlug } from '../../../../../../../services/podcaster/Podcast';
import { validateSlugSpecialCharacter } from '../../../../../../../utils';
import { getRequiredText } from '../../../../../../../utils/formValidationSchema';

// Upload Audio validation schema
export const uploadAudioValidationSchema = Yup.object({
  uploadedAudio: Yup.string().required(getRequiredText('Uploading or recording audio')),
});

// Visual Media validation schema
export const visualMediaValidationSchema = Yup.object({
  // thumbnail: Yup.string().required(getRequiredText('Thumbnail')),
  // cover: Yup.string().required(getRequiredText('Cover')),
});

// Podcast Details validation schema
export const podcastDetailsValidationSchema = Yup.object({
  podcastTitle: Yup.string()
    .trim()
    .required(getRequiredText('Podcast title'))
    .min(4, 'Please enter a title between 4 to 255 characters')
    .max(255, 'Please enter a title between 4 to 255 characters')
    .test(
      'isUnique',
      'This title is already in use. Please choose a different one',
      async (value) => {
        const isUnique = await validateSlug(value); // Check if the slug is unique
        return isUnique; // Return the result of the check
      }
    ),

  slugUrl: Yup.string()
    .required('Slug URL is required. Please provide a valid slug')
    .min(4, 'The slug should be between 4 to 50 characters')
    .max(50, 'The slug should be between 4 to 50 characters')
    .test('specialCharacter', 'Please use only lowercase letters, numbers, and hyphens', (value) =>
      validateSlugSpecialCharacter(value)
    ),

  language: Yup.string().required('Please select a valid podcast language'),
});
