import { ADMIN_APP_ROUTES, DASHBOARD_APP_ROUTES } from '../../constant/appRoute';
import { AdminPanelSidebarDataSource, DashboardSidebarDataSource } from './Data/Sidebar.Data';
import Sidebar from './Sidebar.Section';

export const DashboardSidebar = () => (
  <Sidebar
    displaySubscriptionBox
    logoUrl={`/${DASHBOARD_APP_ROUTES.DASHBOARD}`}
    dataSource={DashboardSidebarDataSource}
  />
);

export const AdminPanelSidebar = () => (
  <Sidebar
    displaySubscriptionBox={false}
    logoUrl={`${ADMIN_APP_ROUTES.ROOT}`}
    dataSource={AdminPanelSidebarDataSource}
  />
);
