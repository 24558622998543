import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Image from '../../../../assets/svg/dashboard/Image.svg';
import { TOASTER_STATUS } from '../../../../constant';
import { DASHBOARD_APP_ROUTES } from '../../../../constant/appRoute';
import useFlashlightEffect from '../../../../hooks/useFlashlightEffect';
import {
  favoritePodcast,
  subscribeUnsubscribePodcast,
} from '../../../../services/listener/PodcastDetails';
import {
  getDate,
  getLocalStorage,
  getTotalPodcastTime,
  getUserTitle,
  showToastMessage,
} from '../../../../utils';
import IconComponent from '../../../Icon/Icon.Component';
import { CardSizesEnum, PodcastCardEnum, PodcastCardTypes } from './PodcastCard.Types';

import DateIcon from '../../../../assets/svg/dashboard/Components/Date';
import PodcastIcon from '../../../../assets/svg/dashboard/Components/Podcast';
import TimeIcon from '../../../../assets/svg/dashboard/Components/Time';
import ShareIcon from '../../../../assets/svg/dashboard/Share';
import { DOMAIN_URL } from '../../../../clientConfig';
import { useLanguage } from '../../../../context/LanguageContext';
import { PodcastItem } from '../../../../services/Podcast/Podcast.Types';
import ActionMenuWrapper from '../../../Dropdown/ActionMenuWrapper';
import PodcastActionMenuItem from '../Data/PodcastActionMenu';
import './PodcastCard.Style.scss';

const PodcastCard: React.FC<PodcastCardTypes> = ({
  podcast,
  className,
  cardSize,
  isFromPodcaster = false,
  type,
  index,
}) => {
  const isAuthenticated = getLocalStorage('accessToken');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(podcast.authorizedUserFavorited || false);

  const [isSubscribe, setIsSubscribe] = useState<boolean>(
    podcast.authorizedUserSubscribed || type === PodcastCardEnum.SUBSCRIBE
  );
  const backgroundColors = [
    { color: '#713CB5', isDark: true, iconColor: '#FFFFFF' },
    { color: '#5A3690', isDark: true, iconColor: '#FFFFFF' },
    { color: '#D9D9D9', isDark: false, iconColor: '#493276' },
    { color: '#5A3690', isDark: true, iconColor: '#FFFFFF' },
  ];

  useFlashlightEffect();

  const handleFavoriteDetail = (value: boolean) => setIsFavorite(value);
  const handleProcessStatus = (value: boolean) => setIsProcessing(value);
  const handleSubscribeDetail = (value: boolean) => setIsSubscribe(value);

  const { language } = useLanguage();
  const [contents, setContents] = useState<any>({
    landingPage: {
      pricing: '',
      unsubscribe: '',
      favorite: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../../../i18n/locales/${language}.json`);
      setContents(langContent);
    };

    loadContent();
  }, [language]);

  const handleFavoritePodcast = async (podcastUuid: string, favorite: boolean) => {
    if (isAuthenticated) {
      if (!isProcessing) {
        handleProcessStatus(true);
        try {
          await favoritePodcast(handleFavoriteDetail, podcastUuid, favorite, handleProcessStatus);
        } catch (error) {
          showToastMessage(TOASTER_STATUS.ERROR, 'An error occurred while favoriting the podcast.');
        } finally {
          handleProcessStatus(false);
        }
      }
    } else {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        <>
          <span className='m-r-10'>Login Required! </span>
          <Link to='/login'>login</Link>
        </>
      );
    }
  };
  const handleSubscribePodcast = async (podcastUuid: string, subscribe: boolean) => {
    if (isAuthenticated) {
      if (!isProcessing) {
        handleProcessStatus(true);
        try {
          await subscribeUnsubscribePodcast(
            handleSubscribeDetail,
            podcastUuid,
            subscribe,
            handleProcessStatus
          );
        } catch (error) {
          showToastMessage(
            TOASTER_STATUS.ERROR,
            'An error occurred while subscribing to the podcast.'
          );
        } finally {
          handleProcessStatus(false);
        }
      }
    } else {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        <>
          <span className='m-r-10'>Login Required! </span>
          <Link to='/login'>login</Link>
        </>
      );
    }
  };

  const handelShare = (currentType: string, podcastData: PodcastItem) => {
    const url = `${DOMAIN_URL}dashboard/podcast-details/${podcastData.slugUrl}`;
    const podcastTitle = encodeURIComponent(podcastData.name);

    switch (currentType) {
      case 'Linkedin': {
        const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${podcastTitle}`;
        window.open(linkedInUrl, '_blank', 'noopener,noreferrer');
        break;
      }
      case 'Facebook': {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        window.open(facebookUrl, '_blank', 'noopener,noreferrer');
        break;
      }
      case 'X': {
        const xUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${podcastTitle}`;
        window.open(xUrl, '_blank', 'noopener,noreferrer');
        break;
      }
      case 'Copy link': {
        navigator.clipboard
          .writeText(url)
          .then(() => showToastMessage(TOASTER_STATUS.SUCCESS, 'Link Copied Successfully'))
          .catch((error) => {
            console.error('Failed to copy URL to clipboard:', error);
            showToastMessage(TOASTER_STATUS.ERROR, 'Failed to Copy Link');
          });
        break;
      }
      default:
        console.warn('Unknown share type:', currentType);
    }
  };

  const isRTL = language === 'ar';

  return podcast?.name ? (
    <div dir={isRTL ? 'rtl' : 'ltr'}>
      <div
        className={`${className || ''} podcast-card ${cardSize} ${backgroundColors[index! % backgroundColors.length].isDark ? 'light' : 'dark'}`}
        key={podcast?.uuid}
        style={{ backgroundColor: backgroundColors[index! % backgroundColors.length].color }}
      >
        <div
          className={`d-flex gap-4 h-100 justify-content-center flex-wrap ${cardSize === CardSizesEnum.SMALL ? 'align-items-start' : 'flex-column align-items-center'}`}
        >
          <div className='d-flex flex-column align-items-center justify-content-between gap-2'>
            <div className='podcast-image-box'>
              <Link
                to={
                  isFromPodcaster
                    ? `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.PODCASTERS}/${podcast?.slugUrl}`
                    : `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.PODCAST_DETAIL}/${podcast?.slugUrl}`
                }
                className='image-link'
              >
                <img
                  className={`podcast-avatar ${podcast?.podcastThumbnailImage ? '' : 'empty-image'}`}
                  src={podcast?.podcastThumbnailImage || Image}
                  alt=''
                />
              </Link>
            </div>
            {type === PodcastCardEnum.FAVORITE && (
              <ul className='d-flex align-items-center gap-4 podcast-action'>
                <li className='d-flex align-items-center gap-3'>
                  <button
                    type='button'
                    className='d-flex align-items-center gap-3'
                    onClick={async () => {
                      await handleFavoritePodcast(podcast.uuid, !isFavorite);
                    }}
                  >
                    <IconComponent
                      name='Podcast'
                      path={(() => {
                        if (isFavorite) {
                          return 'dashboard/FavoriteFill.svg';
                        }
                        const backgroundColor = backgroundColors[index! % backgroundColors.length];
                        return backgroundColor.isDark
                          ? 'dashboard/dark/Favorite.svg'
                          : 'dashboard/purple/Favorite.svg';
                      })()}
                    />
                    <p className='mb-0 action-text'>Favorite</p>
                  </button>
                </li>
                <li className='d-flex'>
                  <ActionMenuWrapper
                    MenuIcon={ShareIcon}
                    items={PodcastActionMenuItem}
                    slugId={podcast.uuid}
                    id={podcast.uuid}
                    onClick={(currentType) => handelShare(currentType as string, podcast)}
                  />
                </li>
              </ul>
            )}
          </div>

          <div className='podcast-title-description h-100'>
            <div className='podcast-title-text'>
              <Link
                to={
                  isFromPodcaster
                    ? `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.PODCASTERS}/${podcast?.slugUrl}`
                    : `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.PODCAST_DETAIL}/${podcast?.slugUrl}`
                }
              >
                <h4 className='text line-truncate-1 mb-1'>{podcast?.name}</h4>
              </Link>
              <p className='text line-truncate-1 mb-1'>{podcast?.description}</p>
              <ul className='mt--15 podcast-info'>
                <li className='d-flex align-items-center gap-3'>
                  <PodcastIcon
                    color={backgroundColors[index! % backgroundColors.length].iconColor}
                  />
                  <p className='mb-0'>{getUserTitle(podcast?.user)}</p>
                </li>
                <li className='d-flex align-items-center gap-3'>
                  <TimeIcon color={backgroundColors[index! % backgroundColors.length].iconColor} />
                  <p className='mb-0'>{getTotalPodcastTime(podcast?.totalDuration, language)}</p>
                </li>
                <li className='d-flex align-items-center gap-3'>
                  <DateIcon color={backgroundColors[index! % backgroundColors.length].iconColor} />
                  <p className='mb-0'>{getDate(podcast.publishedDate, 'DD MMMM YYYY', language)}</p>
                </li>
              </ul>
            </div>
            {(type === PodcastCardEnum.SUBSCRIBE || type === PodcastCardEnum.RECENT) && (
              <div
                className={`d-flex justify-content-between align-items-center w-100 gap-5 ${cardSize === CardSizesEnum.SMALL ? 'mt--15' : 'mt--35'}`}
              >
                <ul className='d-flex align-items-center gap-4 podcast-action'>
                  <li className='d-flex align-items-center gap-3'>
                    <button
                      type='button'
                      className='d-flex align-items-center gap-3'
                      onClick={async () => {
                        await handleFavoritePodcast(podcast.uuid, !isFavorite);
                      }}
                    >
                      <IconComponent
                        name='Podcast'
                        path={(() => {
                          if (isFavorite) {
                            return 'dashboard/FavoriteFill.svg';
                          }
                          const backgroundColor =
                            backgroundColors[index! % backgroundColors.length];
                          return backgroundColor.isDark
                            ? 'dashboard/dark/Favorite.svg'
                            : 'dashboard/purple/Favorite.svg';
                        })()}
                      />
                      <p className='mb-0 action-text'>Favorite</p>
                    </button>
                  </li>
                  <li className='d-flex'>
                    <ActionMenuWrapper
                      MenuIcon={ShareIcon}
                      items={PodcastActionMenuItem}
                      slugId={podcast.uuid}
                      id={podcast.uuid}
                      onClick={(currentType) => handelShare(currentType as string, podcast)}
                    />
                  </li>
                </ul>
                <button
                  type='button'
                  className={`subscribe-btn btn-default btn-border-secondary btn-small${isSubscribe ? ' active' : ''}`}
                  onClick={async () => {
                    await handleSubscribePodcast(podcast?.uuid, !isSubscribe);
                  }}
                >
                  {isSubscribe
                    ? contents.landingPage.unsubscribe || 'Unsubscribe'
                    : contents.landingPage.subscribe || 'Subscribe'}
                </button>
              </div>
            )}
            {/* {!location.pathname.includes('podcaster-podcast') && ( */}
            {/*    <button */}
            {/*        type='button' */}
            {/*        onClick={(e) => { */}
            {/*            e.stopPropagation(); */}
            {/*            navigate(`/podcaster-podcast/${podcast?.user?.uuid}`, { */}
            {/*                state: {podcasterName: getUserTitle(podcast?.user)}, */}
            {/*            }); */}
            {/*        }} */}
            {/*        className='hyperlink hyperlink-black' */}
            {/*    > */}
            {/*        <TooltipWrapper tooltip={getUserTitle(podcast?.user)}> */}
            {/*            {getUserTitle(podcast?.user)} */}
            {/*        </TooltipWrapper> */}
            {/*    </button> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PodcastCard;
