import React from 'react';

const XIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='10' viewBox='0 0 500 452' fill='none'>
    <path
      d='M393.75 0H470.458L302.917 191.446L500 452H345.729L224.813 294.014L86.6042 452H9.77083L188.938 247.178L0 0H158.188L267.375 144.402L393.75 0ZM366.813 406.144H409.292L135.042 43.4603H89.4167L366.813 406.144Z'
      fill='black'
    />
  </svg>
);
export default XIcon;
