import { PodcastItem } from '../../../../services/Podcast/Podcast.Types';

export enum CardSizesEnum {
  SMALL = 'sm',
  LARGE = 'lg',
}

export enum PodcastCardEnum {
  FAVORITE = 'favorite',
  SUBSCRIBE = 'subscribe',
  RECENT = 'recent',
}

export interface PodcastCardTypes {
  podcast: PodcastItem;
  className?: string;
  cardSize: CardSizesEnum.LARGE | CardSizesEnum.SMALL;
  isFromPodcaster?: boolean;
  type: PodcastCardEnum.FAVORITE | PodcastCardEnum.SUBSCRIBE | PodcastCardEnum.RECENT;
  index?: number;
}
