import { useEffect, useState } from 'react';
import PopupTickMark from '../assets/svg/PopupTickMark';
import SvgIcons from '../assets/svg/SvgIcons';
import { useLanguage } from '../context/LanguageContext';

const useEditorContent = () => {
  const { language } = useLanguage(); // Access language context

  const [contents, setContents] = useState({
    editorPage: {
      cancel: '',
      continue: '',
      yourWorkWillBeSavedAsDraft: '',
      upgradeYourPlan: '',
      youNeedToUpgrade: '',
      viewSubscriptionPlan: '',
      paymentFailed: '',
      yourPaymentWasNotSuccessfull: '',
      contactWithUs: '',
      tryAgain: '',
      paymentSuccessfull: '',
      yourPaymentHasBeenSuccessfull: '',
      okay: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../i18n/locales/${language}.json`);
      setContents(langContent);
    };

    loadContent();
  }, [language]);

  return contents;
};

export const PAYMENT_SUCCESS = () => {
  const contents = useEditorContent();
  return {
    BODY: {
      title: contents.editorPage.paymentSuccessfull || 'Payment Successful !',
      content:
        contents.editorPage.yourPaymentHasBeenSuccessfull ||
        'Your payment has been successfully received by Musajjel. Thank you!',
      icon: { Element: PopupTickMark },
    },
    BUTTON_CHILDREN: 'Okay',
  };
};

export const PAYMENT_FAIL = () => {
  const contents = useEditorContent();
  return {
    BODY: {
      title: contents.editorPage.paymentFailed || 'Payment Failed !',
      content:
        contents.editorPage.yourPaymentWasNotSuccessfull ||
        'Your payment was not successfully processed.',
      icon: { Element: SvgIcons, type: 'payment-failed' },
    },
    BUTTON1_CHILDREN: contents.editorPage.contactWithUs || 'Contact with Us',
    BUTTON2_CHILDREN: contents.editorPage.tryAgain || 'Try Again',
  };
};

export const UPGRADE_PLAN = () => {
  const contents = useEditorContent();
  return {
    BODY: {
      title: contents.editorPage.upgradeYourPlan || 'Upgrade Your Plan',
      content:
        contents.editorPage.youNeedToUpgrade ||
        'You need to upgrade your plan for featured your podcasts.',
      icon: { Element: SvgIcons, type: 'upgrade-plan' },
    },
    BUTTON1_CHILDREN: contents.editorPage.viewSubscriptionPlan || 'View Subscription Plan',
  };
};

export const OPEN_EDITOR = () => {
  const contents = useEditorContent();

  return {
    BODY: {
      title:
        contents.editorPage.yourWorkWillBeSavedAsDraft ||
        'Your work will be saved as a draft, and you will be redirected to the editor page.',
      content: '',
    },
    BUTTON1_CHILDREN: contents.editorPage.cancel || 'Cancel',
    BUTTON2_CHILDREN: contents.editorPage.continue || 'Continue',
  };
};
