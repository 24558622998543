import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useField } from 'formik';
import { useLanguage } from '../../context/LanguageContext';
import { getMinutesFromSeconds } from '../../utils';
import DragDropFile from '../DragAndDrop/DragDropFile';
import IconComponent from '../Icon/Icon.Component';
import MicrophonePermissionModal from '../Modal/PermissionModal';
import EnhanceAudio from './EnhanceAudio';
import UploadProgress from './UploadProgress';

interface IProps {
  setStage: (val: number) => void;
  setEnhancedAudio: Dispatch<SetStateAction<string>>;
  setAudioType: Dispatch<SetStateAction<'recorded' | 'uploaded' | ''>>;
  setUploadedFile: (val: string) => void;
  uploadedFile: string;
  isEnhancing: boolean;
  setIsEnhancing: Dispatch<SetStateAction<boolean>>;
  setDownloadPath?: React.Dispatch<React.SetStateAction<string>>;
  handlePageLoading?: (value: boolean) => void;
  status?: string;
  formInstance?: any;
  handleClick?: (value: any, redirectOn?: any) => void;
  initialUploadedAudio?: any;
  isAudioEdited?: boolean;
  isUploading: boolean;
  uploadProcess: number;
  id?: string | null;
  audioName?: string | null;
  redirectToAudioEditor?: (id: string, redirectOn?: any) => void;
  handleAudioChange: (e: File) => void;
  setIsProcessing: (e: boolean) => void;
  uploadAudioDuration: number;
  availableDuration?: number;
  enhanceAudioDuration: number;
  refetchLimits: () => void;
  planUpdated: boolean;
}

const RecordFileData: React.FC<IProps> = ({
  setStage,
  setEnhancedAudio,
  setUploadedFile,
  uploadedFile,
  isEnhancing,
  setIsEnhancing,
  setDownloadPath,
  handlePageLoading,
  status,
  formInstance,
  handleClick,
  initialUploadedAudio,
  isAudioEdited,
  id,
  audioName,
  redirectToAudioEditor,
  handleAudioChange,
  setAudioType,
  isUploading,
  uploadProcess,
  setIsProcessing,
  uploadAudioDuration,
  enhanceAudioDuration,
  refetchLimits,
  planUpdated,
  availableDuration,
}) => {
  const [field, meta, helpers] = useField('uploadedAudio');
  const [showModel, setShowModel] = useState<boolean>(false);

  const { language } = useLanguage();
  const [content, setContent] = useState<any>({
    ManagementPodcastStepsTabs: {
      upload_audio: '',
      record_audio: '',
    },
    podcastPhrases: {
      or: '',
      dragAndDrop: '',
      selectFile: '',
      pleaseUpload: '',
      resolutionFile: '',
      maxFileSize: '',
      maxuploadAudio: '',
      perEpisode: '',
      hr: '',
      hours: '',
      min: '',
      minutes: '',
      sec: '',
      seconds: '',
      yourRemainingDuration: '',
      yourAccountLimitedToDraft: '',
    },
    toastMsgs: {
      podcastUploadedSuccess: '',
      fileSizeMoreThan1KB: '',
      pleaseUploadAudioFile: '',
      fileSizeShouldNotBeMore: '',
      fileSizeTooLarge: '',
      pleaseUploadImageFile: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  const checkMicrophonePermission = async () => {
    try {
      if (navigator?.mediaDevices) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        if (stream) {
          if (uploadAudioDuration > 0) {
            setAudioType('recorded');
            setStage(2);
          }
        } else {
          setShowModel(true);
        }
      }
    } catch (error) {
      setShowModel(true);
      console.error('Error requesting microphone permission:', error);
    }
  };

  if (isUploading) {
    return <UploadProgress progressPercentage={uploadProcess} />;
  }

  return (
    <div className='record-file-data'>
      {!uploadedFile ? (
        <>
          <div className='row'>
            <div className='col-lg-12 form-style'>
              <div className='upload-title'>
                {content.ManagementPodcastStepsTabs.upload_audio || 'Upload Audio'}
              </div>
              <div className='big-height'>
                <DragDropFile
                  accept='audio/*'
                  handleFile={(e) => {
                    setAudioType('uploaded');
                    handleAudioChange(e);
                  }}
                  disabled={uploadAudioDuration < 1}
                  icon={<IconComponent name='Upload' path='dashboard/Upload.svg' />}
                  maxFileSize={500}
                  maxDuration={uploadAudioDuration}
                  availableDuration={availableDuration}
                  sizeIn='MB'
                  width={0}
                  height={0}
                  isAudio
                  isEpisode
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12 form-style '>
              <div className='upload-title'>
                {content.ManagementPodcastStepsTabs.record_audio || 'Record Audio'}
              </div>
              <div className='big-height'>
                <button
                  type='button'
                  className={`upload-audio-wrapper drop-zone d-flex justify-content-center align-items-center gap-5 ${uploadAudioDuration < 1 ? 'cursor-not-allowed' : ''}`}
                  onClick={() => {
                    checkMicrophonePermission();
                  }}
                >
                  <IconComponent name='RecordPodacst' path='dashboard/RecordPodacst.svg' />
                  <span className='drop-zone__prompt d-block'>
                    {uploadAudioDuration && (
                      <div className='drop-zone-note'>
                        {content.podcastPhrases.maximumDuration ||
                          'Maximum duration for audio recordings is'}{' '}
                        {getMinutesFromSeconds(uploadAudioDuration)
                          .replace('hr', content.podcastPhrases.hr || 'hours')
                          .replace('min', content.podcastPhrases.min || 'minutes')
                          .replace('sec', content.podcastPhrases.sec || 'seconds')}
                        {content.podcastPhrases.perEpisodeBrackets || ' (per episode).'}
                        <br />
                        {!!availableDuration &&
                          `${content.podcastPhrases.remainingDuration || 'Your remaining available recording duration is'} 
                          ${getMinutesFromSeconds(availableDuration)
                            .replace('hr', content.podcastPhrases.hr || 'hours')
                            .replace('min', content.podcastPhrases.min || 'minutes')
                            .replace('sec', content.podcastPhrases.sec || 'seconds')}`}
                        {!availableDuration &&
                          `${content.podcastPhrases.accountLimited || 'Your account is currently limited to recording audio only as a draft.'}`}
                      </div>
                    )}
                    {!uploadAudioDuration && (
                      <div className='drop-zone-note'>
                        Sorry. Something went wrong. Please reload this page or try again later.
                      </div>
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <MicrophonePermissionModal show={showModel} onHide={() => setShowModel(false)} />
        </>
      ) : (
        <EnhanceAudio
          setEnhancedAudio={setEnhancedAudio}
          setStage={setStage}
          setUploadedFile={setUploadedFile}
          uploadedFile={uploadedFile}
          isEnhancing={isEnhancing}
          setIsEnhancing={setIsEnhancing}
          setDownloadPath={setDownloadPath}
          handlePageLoading={handlePageLoading}
          status={status}
          formInstance={formInstance}
          handleClick={handleClick}
          initialUploadedAudio={initialUploadedAudio}
          isAudioEdited={isAudioEdited}
          id={id}
          audioName={audioName}
          redirectToAudioEditor={redirectToAudioEditor}
          setIsProcessing={setIsProcessing}
          enhanceAudioDuration={enhanceAudioDuration}
          refetchLimits={refetchLimits}
          planUpdated={planUpdated}
        />
      )}

      {meta.error && meta.touched && (
        <span className='text-danger fs-6 fw-normal error'>{meta.error}</span>
      )}
    </div>
  );
};

RecordFileData.defaultProps = {
  setDownloadPath: undefined,
  handlePageLoading: () => {},
  status: '',
  formInstance: undefined,
  handleClick: () => {},
  initialUploadedAudio: undefined,
  isAudioEdited: false,
  id: null,
  audioName: null,
  redirectToAudioEditor: () => {},
};

export default RecordFileData;
