import React, { FC } from 'react';

const TimeIcon: FC<{ color: string }> = ({ color }) => (
  <svg width='22' height='22' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.25 11.8787L18.1137 16.7425L17.0537 17.8037L11.75 12.5V5H13.25V11.8787ZM12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5C25 19.4037 19.4037 25 12.5 25ZM12.5 23.5C15.4174 23.5 18.2153 22.3411 20.2782 20.2782C22.3411 18.2153 23.5 15.4174 23.5 12.5C23.5 9.58262 22.3411 6.78473 20.2782 4.72183C18.2153 2.65892 15.4174 1.5 12.5 1.5C9.58262 1.5 6.78473 2.65892 4.72183 4.72183C2.65892 6.78473 1.5 9.58262 1.5 12.5C1.5 15.4174 2.65892 18.2153 4.72183 20.2782C6.78473 22.3411 9.58262 23.5 12.5 23.5Z'
      fill={color}
    />
  </svg>
);

export default TimeIcon;
