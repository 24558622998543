import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { IPodcastHistory } from '../../../../../types/listener';
import { getPodcasterPodcasts } from '../../../../../services/listener/Podcast';
import Loader from '../../../../../components/Loader/Loader';
import UserNavSection from '../../UserNav/UserNav.Section';
import PodcastCard from '../../../../../components/Podcast/Cards/Podcast/Podcast.Card';
import {
  CardSizesEnum,
  PodcastCardEnum,
} from '../../../../../components/Podcast/Cards/Podcast/PodcastCard.Types';
import FullPageLoader from '../../../../../components/Loader/FullPageLoader';
import { getAuthorSubscirbeAndRatingDetails } from '../../../../../services/listener/PodcastDetails';
import { displayName } from '../../../../../utils';

const PodcasterPublicProfile = () => {
  const navigate = useNavigate();
  const { podcasterUuid } = useParams();
  const [podcasterDetails, setPodcasterDetails] = useState<any>(null);
  const [podcasts, setPodcasts] = useState<IPodcastHistory[]>([]);
  const [filterTotal, setFilterTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const handlePageLoading = (loading: boolean) => {
    setIsPageLoading(loading);
  };

  const handlePodcastDetails = (details: any) => {
    setPodcasts((prevPodcasts) => prevPodcasts.concat(details?.data || []));
    setFilterTotal((prevTotal) => prevTotal + (details?.recordsFiltered ?? 0));
    setTotal(details?.recordsTotal);
  };

  const handlePage = (_page: number) => {
    setPage(_page);
  };

  const doGetPodcasterPodcasts = (
    _userUuid: string,
    _page: number = 0,
    size: number = 10,
    searchString: string = ''
  ) => {
    getPodcasterPodcasts(
      handlePodcastDetails,
      handlePage,
      handlePageLoading,
      _userUuid,
      _page,
      size,
      searchString
    );
  };

  const fetchMoreData = () => {
    doGetPodcasterPodcasts(podcasterUuid!, page + 1);
  };

  useEffect(() => {
    handlePageLoading(true);
    getAuthorSubscirbeAndRatingDetails(setPodcasterDetails, podcasterUuid!);
    doGetPodcasterPodcasts(podcasterUuid!);
    handlePageLoading(false);
  }, []);

  return (
    <>
      {isPageLoading && <FullPageLoader />}

      {!isPageLoading && (
        <div className='podcast-item-list-container min-vh-100'>
          <div className='d-md-flex d-block align-items-center justify-content-between'>
            <div className='main-title d-flex align-items-center flex-column w-100'>
              <UserNavSection
                title={`${displayName(podcasterDetails?.authorFirstName, podcasterDetails?.authorLastName)}'s podcasts`}
                onBackClick={() => {
                  navigate(-1);
                }}
              />

              {podcasts.length > 0 && (
                <div id='infinite-scrollbar' className='w-100'>
                  <InfiniteScroll
                    dataLength={podcasts?.length}
                    next={() => fetchMoreData()}
                    hasMore={total > filterTotal}
                    loader={<Loader />}
                  >
                    <div className='uniform-result'>
                      {podcasts.map((podcast: any, index) => (
                        <div className='search-item' key={podcast.id}>
                          <PodcastCard
                            podcast={podcast}
                            cardSize={CardSizesEnum.LARGE}
                            type={PodcastCardEnum.RECENT}
                            index={index}
                          />
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
              )}
              {podcasts.length <= 0 && <p>This user has not created any podcasts yet.</p>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PodcasterPublicProfile;
