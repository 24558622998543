import React, { FC } from 'react';

const DateIcon: FC<{ color: string }> = ({ color }) => (
  <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21 2.4H17.4V0.6C17.4 0.44087 17.3368 0.288258 17.2243 0.175736C17.1117 0.0632142 16.9591 0 16.8 0C16.6409 0 16.4883 0.0632142 16.3757 0.175736C16.2632 0.288258 16.2 0.44087 16.2 0.6V2.4H7.8V0.6C7.8 0.44087 7.73679 0.288258 7.62426 0.175736C7.51174 0.0632142 7.35913 0 7.2 0C7.04087 0 6.88826 0.0632142 6.77574 0.175736C6.66321 0.288258 6.6 0.44087 6.6 0.6V2.4H3C2.20464 2.40095 1.44213 2.71733 0.879732 3.27973C0.317329 3.84213 0.000952715 4.60464 0 5.4V21C0.000952715 21.7954 0.317329 22.5579 0.879732 23.1203C1.44213 23.6827 2.20464 23.999 3 24H21C21.7957 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7957 24 21V5.4C24 4.60435 23.6839 3.84129 23.1213 3.27868C22.5587 2.71607 21.7957 2.4 21 2.4ZM22.8 21C22.8 21.4774 22.6104 21.9352 22.2728 22.2728C21.9352 22.6104 21.4774 22.8 21 22.8H3C2.52261 22.8 2.06477 22.6104 1.72721 22.2728C1.38964 21.9352 1.2 21.4774 1.2 21V10.8H22.8V21ZM22.8 9.6H1.2V5.4C1.2 4.4064 2.004 3.6 3 3.6H6.6V5.4C6.6 5.55913 6.66321 5.71174 6.77574 5.82426C6.88826 5.93679 7.04087 6 7.2 6C7.35913 6 7.51174 5.93679 7.62426 5.82426C7.73679 5.71174 7.8 5.55913 7.8 5.4V3.6H16.2V5.4C16.2 5.55913 16.2632 5.71174 16.3757 5.82426C16.4883 5.93679 16.6409 6 16.8 6C16.9591 6 17.1117 5.93679 17.2243 5.82426C17.3368 5.71174 17.4 5.55913 17.4 5.4V3.6H21C21.4774 3.6 21.9352 3.78964 22.2728 4.12721C22.6104 4.46477 22.8 4.92261 22.8 5.4V9.6Z'
      fill={color}
    />
  </svg>
);

export default DateIcon;
