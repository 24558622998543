import X from '../../../../assets/svg/dashboard/SocailMedia/X';
import Linkedin from '../../../../assets/svg/dashboard/SocailMedia/Linkedin';
import Facebook from '../../../../assets/svg/dashboard/SocailMedia/Facebook';
import CopyLinkIcon from '../../../../assets/svg/dashboard/SocailMedia/CopyLink';

const PodcastActionMenuItem = [
  {
    label: 'Linkedin',
    IconName: Linkedin,
    iconType: 'icon-edit',
    url: '',
    hasIcon: true,
    isButton: true,
    slug: null,
  },
  // {
  //   label: 'Instagram',
  //   IconName: Instagram,
  //   iconType: 'icon-delete',
  //   url: '',
  //   hasIcon: true,
  //   isButton: true,
  //   slug: null,
  // },
  {
    label: 'Facebook',
    IconName: Facebook,
    iconType: 'icon-edit',
    url: '',
    hasIcon: true,
    isButton: true,
    slug: null,
  },
  // {
  //   label: 'Youtube',
  //   IconName: Youtube,
  //   iconType: 'icon-delete',
  //   url: '',
  //   hasIcon: true,
  //   isButton: true,
  //   slug: null,
  // },
  {
    label: 'X',
    IconName: X,
    iconType: 'icon-delete',
    url: '',
    hasIcon: true,
    isButton: true,
    slug: null,
  },
  {
    label: 'Copy link',
    IconName: CopyLinkIcon,
    iconType: 'icon-delete',
    url: '',
    hasIcon: true,
    isButton: true,
    slug: null,
  },
];

export default PodcastActionMenuItem;
