import React from 'react';

const FacebookIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512' fill='none'>
    <g clipPath='url(#clip0_1016_4072)'>
      <path
        d='M512 257.557C512 385.323 418.261 491.243 295.893 510.464V332.053H355.392L366.72 258.24H295.893V210.347C295.893 190.144 305.792 170.475 337.493 170.475H369.685V107.627C369.685 107.627 340.459 102.635 312.533 102.635C254.208 102.635 216.107 137.984 216.107 201.963V258.219H151.275V332.032H216.107V510.443C93.76 491.2 0 385.301 0 257.557C0 116.181 114.624 1.55731 256 1.55731C397.376 1.55731 512 116.16 512 257.557Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_1016_4072'>
        <rect width='512' height='512' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default FacebookIcon;
