import { useEffect } from 'react';
import sal from 'sal.js';

const useFlashlightEffect = () => {
  useEffect(() => {
    sal();

    const cards = document.querySelectorAll<HTMLElement>('.bg-flashlight');

    cards.forEach((card) => {
      const flashlight = card as HTMLElement; // Cast the element to HTMLElement

      flashlight.onmousemove = (e: MouseEvent) => {
        const x = e.pageX - flashlight.offsetLeft;
        const y = e.pageY - flashlight.offsetTop;

        flashlight.style.setProperty('--x', `${x}px`);
        flashlight.style.setProperty('--y', `${y}px`);
      };
    });
  }, []);
};

export default useFlashlightEffect;
