import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import AppContext from '../../context/App/AppContext';
import { APP_HOST, SUCCESS_MESSAGE } from '../../constant';
import { getUserDetailAction } from '../../redux/actions/user';
import HeaderDashboard from '../Dashboard/Sections/Header/Header.Section';
import { verifyPodcasterService } from '../../services/podcaster/Auth/Auth';
import AudioPlayerWrapper from '../../components/AudioPlayerWrapper';
import {
  ADVERTISER_APP_ROUTES,
  DASHBOARD_APP_ROUTES,
  PODCASTER_APP_ROUTES as PODCASTER_ROUTES,
} from '../../constant/appRoute';
import { useLanguage } from '../../context/LanguageContext';
import { showAudioPlayer } from '../../redux/selectors/audioPlayer';
import { getEditorEpisodeCount } from '../../redux/selectors/podcaster/audioEditor';
import { DashboardSidebar } from '../../components/Sidebar/Sidebar.Presets';
import { IState } from '../../redux/types';

interface IProps {
  getUserDetail: (host: string, handleLoading: CallableFunction) => void;
  hasAudioPlayer: boolean;
  editorEpisodeCount: number;
}

const DashboardLayout: React.FC<IProps> = ({
  getUserDetail,
  editorEpisodeCount,
  hasAudioPlayer,
}) => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { token } = useParams();
  const location = useLocation();
  const [, setIsLoading] = useState<boolean>(false);

  const handleLoading = (value: boolean) => setIsLoading(value);
  const { language } = useLanguage();

  const redirect = () =>
    navigate(`/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.DISCOVER}`);

  useEffect(() => {
    if (accessToken) {
      handleLoading(true);
      getUserDetail(APP_HOST.PODCASTER, handleLoading);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === `/podcaster/verify-active/${token}`)
      verifyPodcasterService(token!, SUCCESS_MESSAGE.USER_ACTIVATION, redirect);
  }, []);

  return (
    <main className='page-wrapper rbt-dashboard-page'>
      <AppContext>
        <div
          className={`${[
            location.pathname.split('/').at(-1) === PODCASTER_ROUTES.CONTINUE_EDIT
              ? ''
              : 'bottom-pattern',
            hasAudioPlayer ? 'p-b-100' : '',
          ].join(
            ' '
          )}${location.pathname === `${ADVERTISER_APP_ROUTES.ROOT}/${ADVERTISER_APP_ROUTES.CREATE_CAMPAIGN}` ? 'create-campaign-bottom-pattern' : ''}`}
        >
          <div className='rbt-panel-wrapper'>
            {/* <PopupMobileMenu/> */}
            <DashboardSidebar />
            <div className={`rbt-main-content position-relative ${language === 'ar' ? 'rtl' : ''}`}>
              <HeaderDashboard />
              <div className='rbt-dynamic-page-content center-width'>
                <div
                  className={`rbt-dashboard-content ${location.pathname === '/dashboard/continue-edit' ? 'p-0' : ''}`}
                >
                  <Outlet />
                </div>
              </div>
              <AudioPlayerWrapper />
            </div>
          </div>
        </div>
      </AppContext>
    </main>
  );
};

const mapStateToProps = (state: IState) => ({
  hasAudioPlayer: showAudioPlayer(state),
  editorEpisodeCount: getEditorEpisodeCount(state),
});

const mapDispatchToProps = {
  getUserDetail: getUserDetailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
