import { useEffect, useState, type FC } from 'react';
import FormikFieldWrapper from '../../../../../../../../../components/FormikWrapper/FormikFieldWrapper';
import FormikSelectWrapper from '../../../../../../../../../components/FormikWrapper/FormikSelectWrapper';
import FormikTagWrapper from '../../../../../../../../../components/FormikWrapper/FormikTagWrapper';
import FormikTextAreaWrapper from '../../../../../../../../../components/FormikWrapper/FormikTextAreaWrapper';
import { useLanguage } from '../../../../../../../../../context/LanguageContext';
import { getLanguages } from '../../../../../../../../../utils';
import PodcastSlugUrl from '../../../../../../../../podcaster/Podcast/PodcastSlugUrl';
import { PodcastDetailsTypes } from './PodcastDetails.Types';

const PodcastDetailsStep: FC<PodcastDetailsTypes> = ({
  setTitleIntoSlug,
  slug,
  setSlug,
  initialPodcast,
  options,
}) => {
  const { language } = useLanguage();
  const [content, setContent] = useState<any>({
    formLabels: {
      title: '',
      description: '',
      language: '',
      category: '',
      type: '',
      tags: '',
    },
    placeholders: {
      title: '',
      description: '',
      language: '',
      category: '',
      type: '',
      tags: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../../../../../../../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  const isRTL = language === 'ar';

  return (
    <div className={`podcast-details-step ${isRTL ? 'rtl' : ''}`}>
      <div className='d-flex row'>
        <div className='podcast-select-column from-group-wrapper'>
          <FormikFieldWrapper
            label={content.formLabels.title || 'Title'}
            name='podcastTitle'
            type='text'
            placeholder={content.placeholders.title || 'Enter Title'}
            // disabled={
            //   initialPodcast.status.toLowerCase() === PODCAST_STATUS.PUBLISH
            // }
            handleChange={setTitleIntoSlug}
            required
            validateOnChange={{ length: true }}
          />
        </div>
        <div style={{ display: 'none' }}>
          <PodcastSlugUrl slug={slug} setSlug={setSlug} initialSlug={initialPodcast.slugUrl} />
        </div>
      </div>
      <div className='podcast-select'>
        <div className='podcast-select-column'>
          <FormikTextAreaWrapper
            label={content.formLabels.description || 'Description'}
            name='description'
            placeholder={content.placeholders.description || 'Type Description...'}
          />
        </div>
      </div>
      <div className='dropdown-group row podcast-select from-group-wrapper'>
        <div className='col-lg-12 podcast-select-column'>
          <FormikSelectWrapper
            name='language'
            label={content.formLabels.language || 'Language'}
            options={getLanguages()}
            selectSuggestion={content.placeholders.language || 'Select Language'}
            className='mb-0'
            // disabled={
            //   initialPodcast.status.toLowerCase() ===
            //   PODCAST_STATUS.PUBLISH
            // }
            required
          />
        </div>
      </div>
      <div className='dropdown-group row podcast-select from-group-wrapper'>
        <div className='col-lg-6 podcast-select-column'>
          <FormikSelectWrapper
            name='podcastCategory'
            label={content.formLabels.category || 'Category'}
            options={options.categories}
            selectSuggestion={content.placeholders.category || 'Select Category'}
            className='mb-0'
            // disabled={
            //   initialPodcast.status.toLowerCase() ===
            //   PODCAST_STATUS.PUBLISH
            // }
          />
        </div>
        <div className='col-lg-6 podcast-select-column'>
          <FormikSelectWrapper
            name='podcastType'
            label={content.formLabels.type || 'Type'}
            options={options.types}
            selectSuggestion={content.placeholders.type || 'Select Type'}
            className='mb-0'
            // disabled={
            //   initialPodcast.status.toLowerCase() ===
            //   PODCAST_STATUS.PUBLISH
            // }
          />
        </div>
      </div>
      <FormikTagWrapper
        name='tags'
        label={content.formLabels.tags || 'Tags'}
        placeholder={content.placeholders.tags || 'Enter Tags'}
      />
    </div>
  );
};

export default PodcastDetailsStep;
