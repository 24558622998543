import * as Yup from 'yup';
import { getRequiredText } from '../../utils/formValidationSchema';

const ContactSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(25, 'Maximum 25 characters allowed')
    .required(getRequiredText('First name')),
  lastName: Yup.string()
    .trim()
    .max(25, 'Maximum 25 characters allowed')
    .required(getRequiredText('Last name')),
  email: Yup.string()
    .required(getRequiredText('Email'))
    .max(50, 'Maximum 50 characters allowed')
    .email('Please enter a valid email'),
  subject: Yup.string()
    .trim()
    .required(getRequiredText('Subject'))
    .max(100, 'Subject should be less than 100 characters'),
  message: Yup.string()
    .required(getRequiredText('Message'))
    .max(1000, 'Message cannot exceed 1000 characters'),
});

export default ContactSchema;
