import React from 'react';

const LinkedinIcon = () => (
  <svg width='15' height='15' viewBox='0 0 450 452' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M100.344 452H7.01046V150.667H100.344V452ZM109.656 54.6615C109.656 24.4896 85.1771 0 55.0209 0C24.75 0 0.333374 24.4896 0.333374 54.6615C0.333374 84.8438 24.75 109.333 55.0209 109.333C85.1771 109.333 109.656 84.8438 109.656 54.6615ZM449.667 286.219C449.667 205.328 432.578 145.333 338.083 145.333C292.677 145.333 262.198 168.042 249.755 191.656H249.667V150.667H159V452H249.667V302.385C249.667 263.203 259.641 225.245 308.208 225.245C356.115 225.245 357.667 270.052 357.667 304.875V452H449.667V286.219Z'
      fill='#493276'
    />
  </svg>
);
export default LinkedinIcon;
