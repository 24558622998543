import { useEffect, useState } from 'react';
import '../../assets/scss/contact.scss';
import facebookIcon from '../../assets/svg/socials/facebook.svg';
import instagramIcon from '../../assets/svg/socials/instagram.svg';
import linkedInIcon from '../../assets/svg/socials/linkedIn.svg';
import tiktokIcon from '../../assets/svg/socials/tiktok.svg';
import twitterIcon from '../../assets/svg/socials/twitter.svg';
import youtubeIcon from '../../assets/svg/socials/youtube.svg';
import { API_URL } from '../../clientConfig';
import FormikWrapper from '../../components/FormikWrapper';
import FormikFieldWrapper from '../../components/FormikWrapper/FormikFieldWrapper';
import FormikTextAreaWrapper from '../../components/FormikWrapper/FormikTextAreaWrapper';
import { TOASTER_STATUS } from '../../constant';
import { PODCASTER_API_ROUTES } from '../../constant/apiRoute';
import { useAppContext } from '../../context/App/AppContext';
import { useLanguage } from '../../context/LanguageContext';
import AxiosClient from '../../services/AxiosClient';
import { showToastMessage } from '../../utils';
import ContactSchema from './Contact.Schema';

const Contact = () => {
  const { isLightTheme } = useAppContext();
  const { language } = useLanguage();
  const [userEmail, setUserEmail] = useState<string>('');
  const [contents, setContents] = useState<any>({
    contactUsPage: {
      contactUs: '',
      sendUsAMessage: '',
      weWillGetBack: '',
      firstName: '',
      lastName: '',
      emailAdd: '',
      subject: '',
      message: '',
      sendMessage: '',
      messageSentSuccess: '',
      errorSubmittingMessage: '',
      networkError: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../i18n/locales/${language}.json`);
      setContents(langContent);
    };
    const email = localStorage.getItem('userEmail') || '';
    setUserEmail(email);

    loadContent();
  }, [language]);

  const isRTL = language === 'ar';

  const handleSubmit = async (values: any, { setFieldValue }: any) => {
    const formDataToSend = new FormData();
    formDataToSend.append('firstName', values.firstName);
    formDataToSend.append('lastName', values.lastName);
    formDataToSend.append('email', values.email);
    formDataToSend.append('subject', values.subject);
    formDataToSend.append('message', values.message);

    try {
      const response = await AxiosClient.post(
        `${API_URL}${PODCASTER_API_ROUTES.SEND_CONTACT_EMAIL}`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        // Show success message
        showToastMessage(
          TOASTER_STATUS.SUCCESS,
          contents.contactUsPage.messageSentSuccess || 'Your message has been sent successfully!'
        );

        // Clear form fields
        setFieldValue('firstName', '');
        setFieldValue('lastName', '');
        setFieldValue('email', '');
        setFieldValue('subject', '');
        setFieldValue('message', '');
      } else {
        showToastMessage(
          TOASTER_STATUS.ERROR,
          contents.contactUsPage.errorSubmittingMessage ||
            'There was an error submitting your message. Please try again later.'
        );
      }
    } catch (error) {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        contents.contactUsPage.networkError ||
          'Network error: Unable to submit the message. Please try again.'
      );
    }
  };

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'}>
      <div className={`contact-page ${isLightTheme ? 'dark-mode' : 'light-mode'}`}>
        {/* Main Contact Content */}
        <div className='contact-content'>
          <h1>{contents.contactUsPage.contactUs || 'Contact Us'}</h1>

          {/* Send Us A Message Section */}
          <div className='send-message'>
            <h2>{contents.contactUsPage.sendUsAMessage || 'Send us a message'}</h2>
            <p>
              {contents.contactUsPage.weWillGetBack || 'We will get back to you within 24 hours.'}
            </p>

            <FormikWrapper
              initialValues={{
                firstName: '',
                lastName: '',
                email: userEmail,
                subject: '',
                message: '',
              }}
              validationSchema={ContactSchema}
              onSubmit={handleSubmit}
              button={{
                className: 'btn-submit btn-custom',
                children: contents.contactUsPage.sendMessage || 'Send Message',
              }}
            >
              <div className='contact-form'>
                <div className='form-row'>
                  <FormikFieldWrapper
                    label={contents.contactUsPage.firstName || 'First name'}
                    name='firstName'
                    className='col-6'
                    type='text'
                    placeholder={contents.contactUsPage.firstName || 'First name'}
                    required
                  />
                  <FormikFieldWrapper
                    label={contents.contactUsPage.lastName || 'Last name'}
                    name='lastName'
                    className='col-6'
                    type='text'
                    placeholder={contents.contactUsPage.lastName || 'Last name'}
                    required
                  />
                </div>
                <FormikFieldWrapper
                  label={contents.contactUsPage.emailAdd || 'Email Address'}
                  name='email'
                  type='email'
                  value={userEmail} // Use pre-filled value
                  disabled // Make field non-editable
                  placeholder={contents.contactUsPage.emailAdd || 'Email Address'}
                  required
                />
                <FormikFieldWrapper
                  label={contents.contactUsPage.subject || 'Subject'}
                  name='subject'
                  type='text'
                  placeholder={contents.contactUsPage.subject || 'Subject'}
                  required
                />
                <FormikTextAreaWrapper
                  label={contents.contactUsPage.message || 'Message'}
                  name='message'
                  placeholder={contents.contactUsPage.message || 'Message'}
                  maxLength={501}
                  className='mb-4'
                  required
                />
              </div>
            </FormikWrapper>
          </div>

          {/* Find Us Online Section */}
          <div className='find-us-online'>
            <h2>{contents.contactUsPage.findUsOnline || 'Find Us Online'}</h2>
            <p>{contents.contactUsPage.connectWithUs || 'Connect with us on social media.'}</p>
            <div className='social-icons'>
              <a href='https://www.youtube.com' className='social-icon' aria-label='YouTube'>
                <img src={youtubeIcon} alt='YouTube' />
              </a>
              <a
                href='https://www.instagram.com/musajjelinc/'
                className='social-icon'
                aria-label='Instagram'
              >
                <img src={instagramIcon} alt='Instagram' />
              </a>
              <a
                href='https://www.linkedin.com/company/musajjel'
                className='social-icon'
                aria-label='LinkedIn'
              >
                <img src={linkedInIcon} alt='LinkedIn' />
              </a>
              <a href='https://twitter.com/musajjel' className='social-icon' aria-label='X'>
                <img src={twitterIcon} alt='X' />
              </a>
              <a
                href='https://www.facebook.com/musajjelpod'
                className='social-icon'
                aria-label='Facebook'
              >
                <img src={facebookIcon} alt='Facebook' />
              </a>
              <a href='https://www.tiktok.com' className='social-icon' aria-label='TikTok'>
                <img src={tiktokIcon} alt='TikTok' />
              </a>
            </div>
          </div>

          {/* Reach Us Directly Section */}
          <div className='reach-us-directly'>
            <h2>{contents.contactUsPage.reachUsDirectly || 'Reach Us Directly'}</h2>
            <p>
              {contents.contactUsPage.haveQuestionsOrNeed ||
                'Have questions or need assistance? Drop us an email, and we’ll get back to you promptly!'}
            </p>
            <a href='mailto:hello@musajjel.com' className='email-link'>
              hello@musajjel.com
            </a>
          </div>
        </div>

        {/* Right Sidebar */}
        <div className='contact-sidebar contact-sidebar-right' />
      </div>
    </div>
  );
};

export default Contact;
