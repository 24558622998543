import { useEffect, useState } from 'react';
import { useLanguage } from '../context/LanguageContext';

export enum EditorActions {
  ADD_AUDIO = 'add-audio',
  REMOVE_AUDIO = 'remove-audio',
  TRIM_AUDIO = 'trim-audio',
  SPLIT_AUDIO = 'split-audio',
  DUPLICATE_AUDIO = 'duplicate-audio',
  RENAME_AUDIO = 'rename-audio',
  CHANGE_SPEED = 'change-speed',
  CHANGE_VOLUME = 'change-volume',
  CHANGE_TRACK = 'change-track',
  UNDO = 'undo',
}

export const PX_PER_SECOND = 20;
export const TIMELINE_NUMBER_GAP = 5;

// Custom Hook for loading localized content
const useAudioEditorContent = () => {
  const { language } = useLanguage();
  const [contents, setContents] = useState<any>({
    audioEditor: {
      delete: '',
      trim: '',
      split: '',
      duplicate: '',
      rename: '',
      volume: '',
      speed: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../i18n/locales/${language}.json`);
      setContents(langContent);
    };

    loadContent();
  }, [language]);

  return contents;
};

// Functional Component
export const useAudioEditorActionMenuList = () => {
  const contents = useAudioEditorContent();

  return [
    {
      label: contents.audioEditor.trim || 'Trim',
      icon: 'trim-icon',
      key: 'trim',
    },
    {
      label: contents.audioEditor.split || 'Split',
      icon: 'scissor',
      key: 'split',
    },
    {
      label: contents.audioEditor.duplicate || 'Duplicate',
      icon: 'copy',
      key: 'duplicate',
    },
    {
      label: contents.audioEditor.rename || 'Rename',
      icon: 'edit-track',
      key: 'rename',
    },
    {
      label: contents.audioEditor.volume || 'Volume',
      icon: 'volume',
      key: 'volume',
    },
    {
      label: contents.audioEditor.speed || 'Speed',
      icon: 'speed',
      key: 'speed',
    },
    {
      label: contents.audioEditor.delete || 'Delete',
      icon: 'delete-track',
      key: 'delete',
    },
  ];
};
