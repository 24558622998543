import React from 'react';

const CopyLinkIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 299 299' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.712 123.6C44.72 134.592 34.192 145.136 24.608 154.72C8.848 170.464 0 191.824 0 214.112C0 236.384 8.848 257.76 24.608 273.504C40.352 289.264 61.728 298.112 84 298.112C106.288 298.112 127.648 289.264 143.392 273.504C152.976 263.92 163.52 253.392 174.512 242.4C180.752 236.144 180.752 226.016 174.512 219.76C168.272 213.52 158.128 213.52 151.888 219.76C140.88 230.768 130.352 241.296 120.768 250.88C111.024 260.624 97.792 266.112 84 266.112C70.208 266.112 56.992 260.624 47.232 250.88C37.488 241.12 32 227.904 32 214.112C32 200.32 37.488 187.088 47.232 177.344C56.816 167.76 67.344 157.232 78.352 146.224C84.592 139.984 84.592 129.84 78.352 123.6C72.096 117.36 61.968 117.36 55.712 123.6Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M242.4 174.512C253.392 163.52 263.92 152.976 273.504 143.392C289.264 127.648 298.112 106.288 298.112 84C298.112 61.728 289.264 40.352 273.504 24.608C257.76 8.848 236.384 0 214.112 0C191.824 0 170.464 8.848 154.72 24.608C145.136 34.192 134.592 44.72 123.6 55.712C117.36 61.968 117.36 72.096 123.6 78.352C129.84 84.592 139.984 84.592 146.224 78.352C157.232 67.344 167.76 56.816 177.344 47.232C187.088 37.488 200.32 32 214.112 32C227.904 32 241.12 37.488 250.88 47.232C260.624 56.992 266.112 70.208 266.112 84C266.112 97.792 260.624 111.024 250.88 120.768C241.296 130.352 230.768 140.88 219.76 151.888C213.52 158.128 213.52 168.272 219.76 174.512C226.016 180.752 236.144 180.752 242.4 174.512Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M100.976 219.76L219.76 100.976C226.016 94.736 226.016 84.592 219.76 78.352C213.52 72.096 203.376 72.096 197.136 78.352L78.352 197.136C72.096 203.376 72.096 213.52 78.352 219.76C84.592 226.016 94.736 226.016 100.976 219.76Z'
      fill='black'
    />
  </svg>
);
export default CopyLinkIcon;
