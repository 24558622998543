import { lazy, Suspense } from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from '../components/auth/PrivateRoute';
import PublicRoute from '../components/auth/PublicRoute';
import FullPageLoader from '../components/Loader/FullPageLoader';
import APP_ROUTES, {
  DASHBOARD_APP_ROUTES,
  OPEN_APP_ROUTES,
  PODCASTER_APP_ROUTES,
} from '../constant/appRoute';
import ScrollToTop from '../Helpers/ScrollToTop.Helper';
import DashboardLayout from '../pages/layout/Dashboard.Layout';
import DashboardRouters from './Dashboard.Route';
import NoNetwork from './NoNetwork';
import NotFound from './NotFound';

const AdminRoutes = lazy(() => import('./AdminRoutes'));
const Layout = lazy(() => import('../pages/layout/Layout'));
const ResetPasswordPage = lazy(() => import('../pages/Accounts/ResetPassword/ResetPassword.Page'));
const SignupPage = lazy(() => import('../pages/Accounts/Signup/Signup'));
const LoginPage = lazy(() => import('../pages/Accounts/Login/Login'));
const ChangePassword = lazy(
  () => import('../pages/Dashboard/Sections/Pages/ChangePassword/ChangePassword.Page')
);
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy'));
const TermAndCondition = lazy(() => import('../pages/TermAndCondition/TermAndCondition'));
const LandingPage = lazy(() => import('../pages/LandingPage'));
const PodcasterForgotPassword = lazy(
  () => import('../pages/Accounts/ForgotPassword/PodcasterForgotPassword')
);

const Router = () => (
  <Suspense fallback={<FullPageLoader isScreenExist />}>
    <ScrollToTop />

    <Routes>
      <Route
        path={OPEN_APP_ROUTES.ROOT}
        element={
          localStorage.getItem('accessToken') ? (
            <Navigate
              to={`/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.DISCOVER}`}
              replace
            />
          ) : (
            <Layout />
          )
        }
      >
        <Route index element={<LandingPage />} />
        <Route path={`${APP_ROUTES.PRIVACY_POLICY}`} element={<PrivacyPolicy />} />
        <Route path={`${APP_ROUTES.TERM_CONDITION}`} element={<TermAndCondition />} />
      </Route>

      {/* Admin panel routes */}
      <Route
        path='admin/*'
        element={
          <PrivateRoute>
            <AdminRoutes />
          </PrivateRoute>
        }
      />

      {/* Dashboard routes */}
      <Route
        path='dashboard/*'
        element={
          <PrivateRoute>
            <DashboardRouters />
          </PrivateRoute>
        }
      />

      {/* Authentication public routes */}
      <Route
        path={PODCASTER_APP_ROUTES.SIGN_UP}
        element={
          <PublicRoute>
            <SignupPage />
          </PublicRoute>
        }
      />
      <Route
        path={PODCASTER_APP_ROUTES.VERIFY}
        element={
          localStorage.getItem('accessToken') ? (
            <DashboardLayout />
          ) : (
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          )
        }
      />
      <Route
        path={PODCASTER_APP_ROUTES.ACTIVATION}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={PODCASTER_APP_ROUTES.LOGIN}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={`/${PODCASTER_APP_ROUTES.FORGOT_PASSWORD}`}
        element={
          <PublicRoute>
            <PodcasterForgotPassword />
          </PublicRoute>
        }
      />
      <Route path={OPEN_APP_ROUTES.PASSWORD_UPDATE} element={<ChangePassword />} />
      <Route
        path={OPEN_APP_ROUTES.RESET_PASSWORD}
        element={
          <PublicRoute>
            <ResetPasswordPage />
          </PublicRoute>
        }
      />

      {/* 3rd party callbacks */}
      <Route path='/linkedin' element={<LinkedInCallback />} />

      {/* Misc. routes */}
      <Route path={OPEN_APP_ROUTES.NO_NETWORK} element={<NoNetwork />} />
      <Route path={OPEN_APP_ROUTES.NOT_FOUND} element={<NotFound />} />

      {/* Catch all */}
      <Route path={APP_ROUTES.CATCH_ALL} element={<NotFound />} />
    </Routes>
  </Suspense>
);

export default Router;
