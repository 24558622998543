import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../../../../assets/scss/podcast-page.scss';
import { CLOUDINARY_URL } from '../../../../../../clientConfig';
import ModalWrapper from '../../../../../../components/form/ModalWrapper';
import { ITag } from '../../../../../../components/form/TagWrapper';
import FormikWrapper from '../../../../../../components/FormikWrapper';
import FormikButton from '../../../../../../components/FormikWrapper/FormikButton';
import IconComponent from '../../../../../../components/Icon/Icon.Component';
import FullPageLoader from '../../../../../../components/Loader/FullPageLoader';
import { APP_HOST, PODCAST_STATUS, TOASTER_STATUS } from '../../../../../../constant';
import APP_ROUTES, {
  DASHBOARD_APP_ROUTES,
  PODCASTER_APP_ROUTES,
} from '../../../../../../constant/appRoute';
import { UPGRADE_PLAN } from '../../../../../../constant/modal';
import { INITIAL_PODCAST } from '../../../../../../constant/podcast';
import { getEditorEpisodeList } from '../../../../../../redux/actions/podcaster/audioEditor';
import { getUserDetailAction, updateUserDetailAction } from '../../../../../../redux/actions/user';
import { getUser } from '../../../../../../redux/selectors/user';
import { IState } from '../../../../../../redux/types';
import { getUploadAudioDuration } from '../../../../../../services/podcaster/Episode';
import {
  addPodcast,
  setFeaturedPodcast as doFeaturedPodcast,
  getPodcastByUuid,
  getPodcastCategoriesAndTypes,
  updatePodcast,
} from '../../../../../../services/podcaster/Podcast';
import { IPodcastOptions } from '../../../../../../types/podcaster';
import { IPodcastForm, IPodcastUploaded } from '../../../../../../types/podcastInterface';
import {
  getAudioDuration,
  getScheduledDateTime,
  getUuidFromOptionName,
  showToastMessage,
} from '../../../../../../utils';
import { getRequiredText } from '../../../../../../utils/formValidationSchema';
import FeaturedPodcast from '../../../../../podcaster/Podcast/FeaturedPodcast';
import UserNavSection from '../../../UserNav/UserNav.Section';
import {
  ManagementPodcastStepsTabs,
  ManagementPodcastStepsTypeEnumKey,
  TabItemData,
} from './Data/Steps.Tabs';
import { IOpen, ManagementPodcastType } from './ManagementPodcast.Types';
import PodcastDetailsStep from './Sections/Steps/PodcastDetails/PodcastDetails.Step';
import SettingsStep from './Sections/Steps/Settings/Settings.Step';
import UploadAudioStep from './Sections/Steps/UploadAudio/UploadAudio.Step';
import VisualMediaStep from './Sections/Steps/VisualMedia/VisualMedia.Step';

import { useLanguage } from '../../../../../../context/LanguageContext';
import './ManagementPodcast.Style.scss';
import {
  podcastDetailsValidationSchema,
  uploadAudioValidationSchema,
} from './Schema/ManagementPodcast.Schema';
import ReviewStep from './Sections/Steps/Review/Review.Step';

const ManagementPodcastPage: FC<ManagementPodcastType> = ({
  user,
  updateUserDetail,
  getUserDetail,
  getEditorEpisodes,
}) => {
  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();
  const [initialPodcast, setInitialPodcast] = useState<IPodcastForm>(INITIAL_PODCAST);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [, setSubmittingLabel] = useState<string>('');
  const [options, setOptions] = useState<IPodcastOptions>({
    categories: [],
    types: [],
  });

  const { language } = useLanguage();
  const [content, setContent] = useState<any>({
    actions: {
      addPodcast: '',
      editPodcast: '',
      savePodcast: '',
      back: '',
      next: '',
    },
    toastMsgs: {
      somethingWentWrong: '',
      podcastUpdatedSuccess: '',
      podcastCreatedSuccess: '',
      podcastUploadedSuccess: '',
    },
    podcastPhrases: {
      no: '',
      yes: '',
      doYouWantToAddMoreEpisodes: '',
      selectFeaturedDates: '',
    },
    sliders: {
      featuredPodcast: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../../../../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  const [slug, setSlug] = useState<string>('');

  const [hasAudio, setHasAudio] = useState<boolean>(false);
  const [featuredPodcast, setFeaturedPodcast] = useState<boolean>(false);
  // const [trailer, setTrailer] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<IOpen>({
    feature: false,
    upgradePlan: false,
  });
  const [podcastUploaded, setPodcastUploaded] = useState<IPodcastUploaded>({
    isPodcastUploaded: false,
    podcastId: null,
  });
  const [, setAudioRecorderUploaderStage] = useState<number>(0);
  const [uploadAudioDuration, setUploadAudioDuration] = useState<number>(0);
  const [enhanceAudioDuration, setEnhanceAudioDuration] = useState<number>(0);
  const [isPodcastDraft, setIsPodcastDraft] = useState<boolean>(false);
  const [planUpdated, setPlanUpdated] = useState<boolean>(false);
  const [podcastUuid, setPodcastUuid] = useState<string>('');
  const [step, setStep] = useState<{ key: ManagementPodcastStepsTypeEnumKey; index: number }>({
    key: 'podcast_details',
    index: 0,
  });

  const [validationSchemas, setValidationSchemas] = useState([
    podcastDetailsValidationSchema,
    null,
    null,
    null,
    uploadAudioValidationSchema,
    null,
    null,
    null,
  ]);

  const [StepsTabsData, setStepsTabsData] = useState<TabItemData[]>(ManagementPodcastStepsTabs);

  const pathNameList: string[] = pathname.split('/');
  const isEdit = pathNameList.includes(DASHBOARD_APP_ROUTES.EDIT_PODCAST);

  const handlePageLoading = (value: boolean) => setIsPageLoading(value);
  const handleOptions = (value: IPodcastOptions) => setOptions(value);

  const handlePodcast = (value: IPodcastForm) => {
    setInitialPodcast(value);
    if (isEdit) {
      setFeaturedPodcast(value.featured ?? false);
      // setHasScheduled(value.status?.toLowerCase() === PODCAST_STATUS.SCHEDULE);
      setSlug(value.slugUrl);
    }
  };

  const handelAudioDuration = (data: {
    totalAllowedEnhanced: number;
    totalAllowedUpload: number;
    usedEnhanced: number;
    usedUpload: number;
  }) => {
    setUploadAudioDuration(data.totalAllowedUpload - data.usedUpload);
    setEnhanceAudioDuration(data.totalAllowedEnhanced - data.usedEnhanced);
  };

  useEffect(() => {
    setInitialPodcast({
      ...initialPodcast,
      monetized: user?.monetizedAllPodcasts,
    });
  }, [user?.monetizedAllPodcasts]);

  useEffect(() => {
    handlePageLoading(true);
    getPodcastCategoriesAndTypes(handlePageLoading, handleOptions).catch(() => {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        content.toastMsgs.somethingWentWrong || 'Something went wrong'
      );
    });
    if (isEdit) {
      handlePageLoading(true);
      getPodcastByUuid(locationState.podcastId, handlePageLoading, handlePodcast).catch(() => {
        showToastMessage(
          TOASTER_STATUS.ERROR,
          content.toastMsgs.somethingWentWrong || 'Something went wrong'
        );
      });
    }
  }, []);

  useEffect(() => {
    if (user.uuid) {
      getUploadAudioDuration(user.uuid, handelAudioDuration).catch(() => {
        showToastMessage(
          TOASTER_STATUS.ERROR,
          content.toastMsgs.somethingWentWrong || 'Something went wrong'
        );
      });
    }
  }, [user.uuid]);

  // Function to go to the next step
  const handleNextStep = () => {
    if (step.index < StepsTabsData.length - 1) {
      setStep((prevState) => ({
        index: prevState.index + 2,
        key: StepsTabsData[prevState.index + 2].key,
      }));
    }
  };

  // Function to go to the previous step
  const handlePrevStep = () => {
    if (step.index > 1) {
      setStep((prevState) => ({
        index: prevState.index - 2,
        key: StepsTabsData[prevState.index - 2].key,
      }));
    }
  };

  const onSetHasAudio = (value: boolean) => setHasAudio(value);

  const getSubmittingStatus = (status: string) => {
    switch (status) {
      case PODCAST_STATUS.DRAFT:
        return 'Saving as draft';

      case PODCAST_STATUS.SCHEDULE:
        return 'Scheduling';

      case PODCAST_STATUS.PUBLISH:
        return 'Publishing';

      default:
        return 'Saving';
    }
  };

  const handleRedirect = async (isAddUpdate?: boolean, slugUrl?: string) => {
    if (isEdit) {
      navigate(
        `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.PODCAST_CREATOR_DETAIL}/${slugUrl}`,
        {
          state: {
            redirectTo: `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.MY_PODCAST}`,
          },
        }
      );
      if (isAddUpdate) {
        showToastMessage(
          TOASTER_STATUS.SUCCESS,
          content.toastMsgs.podcastUpdatedSuccess || 'Podcast Updated Successfully'
        );
      }
    } else {
      navigate(
        `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.PODCAST_CREATOR_DETAIL}/${slugUrl}`,
        {
          state: {
            redirectTo: `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.MY_PODCAST}`,
          },
        }
      );
      if (isAddUpdate) {
        const data = {
          episodeCount: (user?.episodeCount || 0) + 1,
          podcastCount: (user?.podcastCount || 0) + 1,
        };
        await updateUserDetail(data);
        showToastMessage(
          TOASTER_STATUS.SUCCESS,
          content.toastMsgs.podcastCreatedSuccess || 'Podcast Created Successfully'
        );
      }
    }
  };

  const handleOpen = (key: keyof IOpen) =>
    setIsOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the value for the given key
    }));
  const onFeaturedPodcast = async (evt: ChangeEvent<HTMLInputElement>) => {
    const isChecked = evt.target.checked;

    if (isChecked && initialPodcast.upgradePlanForFeatured) {
      handleOpen('upgradePlan');
    } else {
      setFeaturedPodcast(isChecked);
      if (!isChecked) {
        await doFeaturedPodcast(locationState.podcastId, false, 0, 0, handleOpen);
      } else {
        handleOpen('feature');
      }
    }
  };

  const openPodcastUploaded = (podcastId: string) => {
    setPodcastUploaded({
      isPodcastUploaded: true,
      podcastId,
    });
  };
  const redirectToAudioEditor = (
    id: string,
    redirectOn: any,
    podcastId?: string,
    slugUrl?: string
  ) => {
    setPodcastUuid(podcastId ?? '');
    if (redirectOn?.isOpenEditor) {
      navigate(`/${DASHBOARD_APP_ROUTES.DASHBOARD}/${PODCASTER_APP_ROUTES.CONTINUE_EDIT}`, {
        state: {
          episodeId: id,
          path: `${PODCASTER_APP_ROUTES.ROOT}/${slugUrl}`,
          podcastId,
        },
      });
    } else if (redirectOn?.isRedirectOnSubscription) {
      setIsPodcastDraft(true);
      const updatePlanWindow = window.open(
        `${DASHBOARD_APP_ROUTES.DASHBOARD}/${APP_ROUTES.SUBSCRIPTION}`,
        '_blank'
      );
      window.addEventListener('message', (event) => {
        if (event.data === 'planUpdated') {
          setPlanUpdated(true);
          getUserDetail(APP_HOST.PODCASTER);
          updatePlanWindow?.close();
        }
      });
    }
  };

  const onAddPodcast = async (values: IPodcastForm, redirectOn?: any) => {
    setIsSubmitting(true);
    setSubmittingLabel(getSubmittingStatus(values.status));

    const duration: number = await new Promise((resolve) => {
      const audioFile = values.enhancedAudio || values.uploadedAudio;
      getAudioDuration(audioFile, (audioDuration: number) => {
        resolve(audioDuration);
      });
    });

    if (duration) {
      const {
        thumbnail,
        cover,
        podcastCategory,
        podcastType,
        podcastTitle,
        description,
        country,
        // eslint-disable-next-line
        language,
        slugUrl,
        tags,
        status,
        uploadedAudio,
        enhancedAudio,
        sentimentNames,
        date,
        time,
        monetized,
        warnListeners,
        trailer,
        transcriptUuid,
      } = values;

      const podcast: any = {
        categoryUuid: getUuidFromOptionName(options.categories, podcastCategory),
        podcastTypeUuid: getUuidFromOptionName(options.types, podcastType),
        name: podcastTitle,
        description,
        podcastCountry: country,
        podcastLanguage: language,
        tagNames: tags.map((tag: ITag) => tag.value),
        episodeTagNames: [],
        podcastStatus: status?.toUpperCase(),
        slugUrl,
        monetized,
        warnListeners,
        trailer,
        episodeSentiments: sentimentNames,
        scheduledDate: getScheduledDateTime(date, time, language),
        podcastDuration: duration,
        audioFile: enhancedAudio ? 'PROCESSED' : 'RECORDED',
        transcriptUuid,
        recorderAudioFile: uploadedAudio.replace(CLOUDINARY_URL ?? '', ''),
        processedAudioFile: (enhancedAudio as string).replace(CLOUDINARY_URL ?? '', ''),
      };

      await addPodcast(
        podcast,
        thumbnail,
        cover,
        handleRedirect,
        handlePageLoading,
        openPodcastUploaded,
        redirectOn,
        redirectToAudioEditor
      );
    }
    setIsSubmitting(false);
  };

  const onViewUpgradePlan = () => {
    handleOpen('upgradePlan');
    navigate(`${DASHBOARD_APP_ROUTES.DASHBOARD}/${APP_ROUTES.SUBSCRIPTION}`);
  };

  const handleSubscriptionPlanClose = () => {
    setFeaturedPodcast(false);
    handleOpen('upgradePlan');
  };

  const handleFeatureClose = () => {
    setFeaturedPodcast(false);
    handleOpen('feature');
  };

  const onUpdatePodcast = async (values: any) => {
    setIsSubmitting(true);
    setSubmittingLabel(getSubmittingStatus(values.status));
    await updatePodcast(
      values,
      locationState?.podcastId ?? podcastUuid,
      handleRedirect,
      handlePageLoading,
      options,
      initialPodcast.status.toLowerCase()
    );
    if (values.status === PODCAST_STATUS.PUBLISH) {
      getEditorEpisodes();
    }
    setIsSubmitting(false);
  };

  const setTitleIntoSlug = (value: string) => {
    const slugValue = value
      .replace(/[^\p{L}\p{N}\s]/gu, '') // Replace any non-letter, non-digit character with space
      .replace(/\s+/g, '-') // Replace whitespace with hyphen
      .toLowerCase();

    const trimmedSlug = slugValue.substring(0, 50).endsWith('-')
      ? slugValue.substring(0, 49)
      : slugValue.substring(0, 50);

    setSlug(trimmedSlug);
  };

  const onNoPodcastUploaded = () => {
    setPodcastUploaded({
      isPodcastUploaded: false,
      podcastId: null,
    });
    navigate(`/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.MY_PODCAST}`);
  };

  const onYesPodcastUploaded = () => {
    navigate(
      `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${slug}/${PODCASTER_APP_ROUTES.ADD_EPISODE}/${podcastUploaded.podcastId}`
    );
  };

  const handleAudioRecorderUploaderStage = (value: number) => setAudioRecorderUploaderStage(value);

  const handleValidate = (values: any) => {
    const errors: any = {};
    if (values?.status === 'scheduled') {
      if (!values?.date || values?.date === 'Invalid date') {
        errors.date = getRequiredText('Date');
      }
      if (!values?.time || values?.time === 'Invalid date') {
        errors.time = getRequiredText('Time');
      }
    }
    return errors;
  };

  useEffect(() => {
    if (isEdit) {
      setStepsTabsData((prevState) =>
        prevState.filter(
          (item, index) => item.key !== 'upload_audio' && !(item.key === 'separator' && index === 5)
        )
      );
      setValidationSchemas([podcastDetailsValidationSchema, null, null, null, null, null]);
    } else {
      setStepsTabsData(ManagementPodcastStepsTabs);
      setInitialPodcast(INITIAL_PODCAST);
      setValidationSchemas([
        podcastDetailsValidationSchema,
        null,
        null,
        null,
        uploadAudioValidationSchema,
        null,
        null,
        null,
      ]);
    }
  }, [isEdit, setStepsTabsData, setValidationSchemas, setValidationSchemas, setInitialPodcast]);

  useEffect(() => {
    const loadTranslatedTabs = async () => {
      const langContent = await import(`../../../../../../i18n/locales/${language}.json`);
      const translatedTabs = ManagementPodcastStepsTabs.map((tab) => ({
        ...tab,
        label: langContent.ManagementPodcastStepsTabs[tab.key], // Dynamically set label from the translation
      }));
      setStepsTabsData(translatedTabs);
    };

    loadTranslatedTabs();
  }, [language]);

  const upgradePlan = UPGRADE_PLAN();

  return (
    <div className='podcast-page management-podcast-wrapper'>
      {(isPageLoading || isSubmitting) && <FullPageLoader isScreenExist />}
      <UserNavSection
        title={`${isEdit ? content.actions.editPodcast : content.actions.addPodcast}`}
        onBackClick={isEdit ? () => navigate(-1) : null}
      />

      {/* Step Header with Arrows */}
      <div
        className={`steps-wrapper d-flex justify-content-center align-items-center mt-4 ${
          language === 'ar' ? 'rtl-steps' : ''
        }`}
      >
        {(language === 'ar' ? [...StepsTabsData].reverse() : StepsTabsData).map(
          (stepTab, index) => (
            <React.Fragment key={`${stepTab.key + index}`}>
              <div
                className={`${stepTab.key === step.key ? 'active ' : ''}${index + 1 < step.index ? 'pre-active ' : ''}management-steps-label`}
              >
                {stepTab.label}
              </div>
              {stepTab.inlineTrailingIcon && (
                <IconComponent
                  name='StepsArrow'
                  path='dashboard/StepsArrow.svg'
                  className={`mx-5 ${language === 'ar' ? 'arrow-rtl' : ''}`}
                />
              )}
            </React.Fragment>
          )
        )}
      </div>

      <FormikWrapper
        initialValues={initialPodcast}
        validationSchema={validationSchemas[step.index]}
        button={{
          className: '',
          children: '',
          isHide: true,
        }}
        onSubmit={async (values: any) => {
          if (step.index === validationSchemas.length) {
            if (isEdit || isPodcastDraft) {
              await onUpdatePodcast(values);
            } else {
              await onAddPodcast(values);
            }
          } else {
            handleNextStep();
          }
        }}
        validate={handleValidate}
      >
        <div className='row form-margin form-body'>
          {/* Form Content */}
          <div className='form-content'>
            {step.key === 'upload_audio' && !isEdit && (
              <UploadAudioStep
                onSetHasAudio={onSetHasAudio}
                initialPodcast={initialPodcast}
                isPodcastDraft={isPodcastDraft}
                onAddPodcast={onAddPodcast}
                redirectToAudioEditor={redirectToAudioEditor}
                handleAudioRecorderUploaderStage={handleAudioRecorderUploaderStage}
                setIsProcessing={setIsProcessing}
                uploadAudioDuration={uploadAudioDuration}
                enhanceAudioDuration={enhanceAudioDuration}
                handelAudioDuration={handelAudioDuration}
                planUpdated={planUpdated}
                isEdit={isEdit}
                user={user}
              />
            )}
            {step.key === 'visual_media' && <VisualMediaStep />}
            {step.key === 'podcast_details' && (
              <PodcastDetailsStep
                setTitleIntoSlug={setTitleIntoSlug}
                slug={slug}
                setSlug={setSlug}
                initialPodcast={initialPodcast}
                options={options}
              />
            )}
            {step.key === 'appearance_settings' && (
              <SettingsStep
                isEdit={isEdit}
                featuredPodcast={featuredPodcast}
                onFeaturedPodcast={onFeaturedPodcast}
                initialPodcast={initialPodcast}
                podcastUuid={podcastUuid}
                isProcessing={isProcessing}
                isSubmitting={isSubmitting}
                hasAudio={hasAudio}
                availableDuration={uploadAudioDuration}
              />
            )}
            {step.key === 'review' && <ReviewStep />}
          </div>
        </div>

        <div
          className={`d-flex justify-content-between mt-3 form-actions ${
            language === 'ar' ? 'rtl-actions' : ''
          }`}
        >
          {step.index > 1 && (
            <button
              type='button'
              className='wizard-btn back-btn d-flex align-items-center gap-2'
              onClick={handlePrevStep}
            >
              <IconComponent
                name='StepsArrow'
                path={
                  language === 'ar' ? 'dashboard/NextArrow.svg' : 'dashboard/purple/PrevArrow.svg'
                }
              />
              {content.actions.back}
            </button>
          )}

          {step.index < StepsTabsData.length - 1 ? (
            <FormikButton
              type='submit'
              className='wizard-btn next-btn d-flex align-items-center gap-2'
            >
              {content.actions.next}
              <IconComponent
                name='NextArrow'
                path={
                  language === 'ar' ? 'dashboard/purple/PrevArrow.svg' : 'dashboard/NextArrow.svg'
                }
              />
            </FormikButton>
          ) : (
            <button type='submit' className='wizard-btn next-btn py-4 ms-auto'>
              <b>{content.actions.savePodcast}</b>
            </button>
          )}
        </div>

        {/*  audioRecorderUploaderStage !== 4 && ( */}
        {/*    <div className='row'> */}
        {/*      <div className='col-lg-6'> */}
        {/*        <div */}
        {/*          className={`form-margin form-btn ${ */}
        {/*            isEdit && */}
        {/*            initialPodcast.status.toLowerCase() === PODCAST_STATUS.SCHEDULE && */}
        {/*            'position-relative' */}
        {/*          }`} */}
        {/*        > */}
        {/*          {!hasScheduled && hasAudio && !isProcessing && !isEdit && ( */}
        {/*            <> */}
        {/*              <FormikButton */}
        {/*                name='status' */}
        {/*                value='draft' */}
        {/*                type='submit' */}
        {/*                className='reject-btn' */}
        {/*              > */}
        {/*                Save as Draft */}
        {/*              </FormikButton> */}
        {/* <FormikButton name='status' value='published' type='submit'> */}
        {/*  Publish */}
        {/* </FormikButton> */}
        {/*            </> */}
        {/*          )} */}
        {/*          {isEdit && !hasScheduled && ( */}
        {/*            <FormikButton name='status' value={initialPodcast.status} type='submit'> */}
        {/*              Save */}
        {/*            </FormikButton> */}
        {/*          )} */}

        {/*          {initialPodcast.status.toLowerCase() !== PODCAST_STATUS.PUBLISH && */}
        {/*            isEdit && */}
        {/*            (!hasScheduled || */}
        {/*              initialPodcast.status.toLowerCase() === PODCAST_STATUS.SCHEDULE) && ( */}
        {/*              <FormikButton */}
        {/*                name='status' */}
        {/*                value='published' */}
        {/*                type='submit' */}
        {/*                className={ */}
        {/*                  initialPodcast.status.toLowerCase() === PODCAST_STATUS.SCHEDULE */}
        {/*                    ? 'edit-save-btn' */}
        {/*                    : '' */}
        {/*                } */}
        {/*              > */}
        {/*                Publish */}
        {/*              </FormikButton> */}
        {/*            )} */}
        {/*        </div> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  )} */}
      </FormikWrapper>

      {isOpen.feature && (
        <ModalWrapper
          size='lg'
          show={isOpen.feature}
          handleClose={handleFeatureClose}
          body={{
            title: content.sliders.featuredPodcast,
            content: content.podcastPhrases.selectFeaturedDates,
          }}
          button1={{
            children: '',
            onClick: () => {},
          }}
          customElement={
            <FeaturedPodcast
              podcastId={locationState.podcastId}
              featured={featuredPodcast}
              handleOpen={handleOpen}
            />
          }
          isButton={false}
        />
      )}

      {isOpen.upgradePlan && (
        <ModalWrapper
          size='lg'
          show={isOpen.upgradePlan}
          handleClose={handleSubscriptionPlanClose}
          body={{
            title: upgradePlan.BODY.title,
            content: upgradePlan.BODY.content,
            icon: upgradePlan.BODY.icon,
          }}
          button1={{
            children: upgradePlan.BUTTON1_CHILDREN,
            onClick: onViewUpgradePlan,
          }}
          bodyClass='upgrade-plan-modal-body'
        />
      )}

      {podcastUploaded.isPodcastUploaded && (
        <ModalWrapper
          size='lg'
          show={podcastUploaded.isPodcastUploaded}
          handleClose={onNoPodcastUploaded}
          body={{
            title: content.toastMsgs.podcastUploadedSuccess,
            content: content.podcastPhrases.doYouWantToAddMoreEpisodes,
            imgPath: 'dashboard/Alert/Success.svg',
          }}
          modalType='submit'
          button1={{
            children: content.podcastPhrases.no,
            onClick: onNoPodcastUploaded,
            className: 'close-btn',
          }}
          button2={{
            children: content.podcastPhrases.yes,
            onClick: onYesPodcastUploaded,
            className: 'save-btn',
          }}
          className='podcast-uploaded-modal'
          isButton
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  user: getUser(state),
});

const mapDispatchToProps = {
  updateUserDetail: updateUserDetailAction,
  getUserDetail: getUserDetailAction,
  getEditorEpisodes: getEditorEpisodeList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagementPodcastPage);
