import { useEffect, useState, type FC } from 'react';

import { useFormikContext } from 'formik';
import EmptyImage from '../../../../../../../../../assets/svg/dashboard/EmptyImage.svg';
import { useLanguage } from '../../../../../../../../../context/LanguageContext';
import { IPodcastForm } from '../../../../../../../../../types/podcastInterface';
import { ReviewType } from './Review.Types';

const ReviewStep: FC<ReviewType> = () => {
  const { values } = useFormikContext<IPodcastForm>();

  const imageSrc = (img: any, type?: string) => {
    if (typeof img === 'string') {
      return img;
    }
    if (img) {
      return URL.createObjectURL(img as Blob);
    }
    return type === 'thumbnail' ? EmptyImage : '';
  };

  const { language } = useLanguage();
  const [content, setContent] = useState<any>({
    formLabels: {
      title: '',
      description: '',
      category: '',
      language: '',
      type: '',
      countryOfOrigin: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../../../../../../../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  const isRTL = language === 'ar';

  return (
    <div className={`podcast-details-step ${isRTL ? 'rtl' : ''}`}>
      <div className='d-flex flex-column review-wrapper'>
        <div className='images'>
          <img
            src={values.thumbnail ? imageSrc(values.thumbnail, 'thumbnail') : EmptyImage}
            alt={values.podcastTitle}
            className='image-view thumbnail-bg'
          />
          <div
            style={{
              backgroundImage: `url(${imageSrc(values.cover)})`,
            }}
            className='image-view background-bg background-review'
          />
        </div>
        <span>{content.formLabels.title || 'title'}</span>
        <h3>{values.podcastTitle}</h3>
        <span>{content.formLabels.description || 'Description'}</span>
        <p className='description mb-5'>{values.description}</p>

        <div className='row mb-5'>
          <div className='col-12 col-lg-6'>
            <span>{content.formLabels.category || 'Category'}</span>
            <p className='country'>{values.podcastCategory}</p>
          </div>
          <div className='col-12 col-lg-6'>
            <span>{content.formLabels.type || 'Type'}</span>
            <p className='country'>{values.podcastType}</p>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-12 col-lg-6'>
            <span>{content.formLabels.language || 'Language'}</span>
            <p className='country'>{values.language}</p>
          </div>
          <div className='col-12 col-lg-6'>
            <span>{content.formLabels.countryOfOrigin || 'Country of Origin'}</span>
            <p className='country'>{values.country}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewStep;
