import { faCheck, faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { DOMAIN_URL } from '../../../clientConfig';
import FormikInputGroup from '../../../components/FormikWrapper/FormikInputGroup';
import { useLanguage } from '../../../context/LanguageContext';

const SlugCustomElement = ({ status }: { status: string }) => {
  if (status === 'loading') {
    return <FontAwesomeIcon icon={faSpinner} spin />;
  }
  if (status === 'success') {
    return <FontAwesomeIcon icon={faCheck} className={status} />;
  }
  if (status === 'failure') {
    return <FontAwesomeIcon icon={faCircleExclamation} className={status} />;
  }
  return null;
};

interface PodcastSlugUrlProps {
  slug: string;
  setSlug: (value: string) => void;
  disabled?: boolean;
  initialSlug: string;
}

const PodcastSlugUrl = ({ slug, setSlug, initialSlug, disabled }: PodcastSlugUrlProps) => {
  const [slugStatus, setSlugStatus] = useState<string>('');
  const [field, meta, helper] = useField({ name: 'slugUrl' });

  const handleSlug = (value: string) => {
    setSlug(value.toLowerCase());
  };

  const { language } = useLanguage();
  const [content, setContent] = useState<any>({
    formLabels: {
      slugUrl: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  return (
    <div className='slug-url'>
      <FormikInputGroup
        label={content.formLabels.slugUrl || 'Slug Url'}
        name='slugUrl'
        textValue={DOMAIN_URL ?? ''}
        type='text'
        customElement={slug !== '' ? <SlugCustomElement status={slugStatus} /> : null}
        handleChange={handleSlug}
        disabled={disabled}
        value={slug}
        hasValue
        hasError={slugStatus === 'failure'}
        required
      />
    </div>
  );
};

export default PodcastSlugUrl;
