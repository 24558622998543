import { ConnectedFocusError } from 'focus-formik-error';
import { Form, Formik } from 'formik';
import { FC, ReactNode } from 'react';
import ButtonWrapper from '../form/ButtonWrapper';

interface IButton {
  className: string;
  children: ReactNode | string;
  isDisabled?: boolean;
  isHide?: boolean;
  cancelButton?: string;
  onCancelButton?: () => void;
}

interface IProps {
  initialValues: any;
  validationSchema: any;
  onSubmit: CallableFunction;
  children: ReactNode | Array<ReactNode>;
  button: IButton;
  validate?: any;
}

const FormikWrapper: FC<IProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  children,
  button,
  validate,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(data, { setFieldValue }) => onSubmit(data, { setFieldValue })}
    validate={validate}
    enableReinitialize
  >
    {() => (
      <Form>
        <ConnectedFocusError />
        {children}

        {!button.isHide && (
          <div className='actions-button d-flex justify-content-end gap-4'>
            {button.onCancelButton && (
              <ButtonWrapper
                type='button'
                onClick={button.onCancelButton}
                className='btn-default tertiary-btn'
              >
                {button.cancelButton}
              </ButtonWrapper>
            )}
            <ButtonWrapper
              type='submit'
              className={button.className}
              isDisabled={button.isDisabled}
            >
              {button.children}
            </ButtonWrapper>
          </div>
        )}
      </Form>
    )}
  </Formik>
);

FormikWrapper.defaultProps = {
  validate: () => ({}),
};

export default FormikWrapper;
