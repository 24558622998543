import { useEffect, useState } from 'react';
import { BeforeAfterRecordingInterface } from '../../types/audioContextInterface';

import { CLOUDINARY_URL } from '../../clientConfig';
import { useLanguage } from '../../context/LanguageContext';
import { acceptEnhanceAudio } from '../Action/Image&AudioUpload';
import Loader from '../Loader/Loader';
import AudioWave from './AudioWave';

const BeforeAfterRecording = ({
  setStage,
  uploadedFile,
  enhancedAudio,
  audioType,
  setEnhancedAudio,
  isUploading,
  setIsUploading,
}: BeforeAfterRecordingInterface) => {
  const [audioLoading, setAudioLoading] = useState<boolean>(true);

  const handleEnhancedAudio = () => {
    setIsUploading(true);
    acceptEnhanceAudio(
      enhancedAudio?.replace(CLOUDINARY_URL ?? '', '').replace('/processed', ''),
      () => {
        setStage(5);
        setIsUploading(false);
        setEnhancedAudio(enhancedAudio.replace('/processed', ''));
      },
      () => setIsUploading(false)
    );
  };

  const handleRejectAudio = () => {
    setEnhancedAudio('');
    if (audioType === 'uploaded') {
      setStage(0);
    } else {
      setStage(3);
    }
  };

  const { language } = useLanguage();
  const [content, setContent] = useState<any>({
    enhanceAudio: {
      reject: '',
      accept: '',
      pleaseWait: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  return (
    <div className='row'>
      <div className='col-lg-6'>
        <div className='big-height record-audio mb-4 position-relative old-audio'>
          <AudioWave
            classString='enhance-wave'
            color='befor'
            link={uploadedFile}
            audioLoading={audioLoading}
            setAudioLoading={setAudioLoading}
          />
        </div>
      </div>
      <div className='col-lg-6'>
        <div className='big-height record-audio mb-4 position-relative new-audio'>
          <AudioWave
            classString='enhance-wave'
            link={enhancedAudio ?? ''}
            audioLoading={audioLoading}
            setAudioLoading={setAudioLoading}
          />
        </div>
      </div>
      {!isUploading ? (
        <div className='enhance-btn d-flex align-items-center gap-4 justify-content-center m-auto'>
          <button
            type='button'
            className='btn-primary btn-default tertiary-btn w-50'
            onClick={handleRejectAudio}
          >
            {content.enhanceAudio.reject || 'Reject'}
          </button>
          <button
            type='button'
            className='btn-primary btn-default w-50'
            onClick={handleEnhancedAudio}
          >
            {content.enhanceAudio.accept || 'Accept'}
          </button>
        </div>
      ) : (
        <button
          type='button'
          disabled
          className='btn btn-primary btn-style w-100 ms-2 btn-bg gap-3'
        >
          <Loader /> {content.enhanceAudio.pleaseWait || 'Please wait'}
        </button>
      )}
    </div>
  );
};
export default BeforeAfterRecording;
