import { USER_ROLE } from '../../../constant';
import { ADMIN_APP_ROUTES, DASHBOARD_APP_ROUTES } from '../../../constant/appRoute';
import { SidebarItemCategory } from './Sidebar.Types';

export const DashboardSidebarDataSource: SidebarItemCategory[] = [
  {
    id: 'Podcast Management Section',
    text: 'Podcast Management', // Placeholder, will be replaced with translations
    items: [
      {
        id: 'add_new',
        icon: 'AddNew',
        label: 'New Podcast', // Placeholder for translation
        link: '/dashboard/podcast-add',
      },
      {
        id: 'my_podcast',
        icon: 'MyPodcast',
        label: 'My Podcast', // Placeholder for translation
        link: '/dashboard/my-podcast',
      },
      {
        id: 'draft',
        icon: 'Draft',
        label: 'Draft', // Placeholder for translation
        link: '/dashboard/draft',
      },
      {
        id: 'analytics',
        icon: 'Analytics',
        label: 'Analytics', // Placeholder for translation
        link: '/dashboard/analytics',
      },
    ],
  },
  {
    id: 'Library Section',
    text: 'Library', // Placeholder for translation
    items: [
      {
        id: 'discover',
        icon: 'Discover',
        label: 'Discover', // Placeholder for translation
        link: '/dashboard/discover',
      },
      {
        id: 'favorite',
        icon: 'Favorite',
        label: 'Favorite', // Placeholder for translation
        link: '/dashboard/favorites',
      },
      {
        id: 'downloads',
        icon: 'Downloads',
        label: 'Downloads', // Placeholder for translation
        link: '/dashboard/downloads',
      },
      {
        id: 'subscriptions',
        icon: 'Notification',
        label: 'Subscriptions', // Placeholder for translation
        link: '/dashboard/subscriptions/podcasts',
      },
      {
        id: 'recent',
        icon: 'Recent',
        label: 'Recent', // Placeholder for translation
        link: '/dashboard/recent',
      },
    ],
  },
  {
    id: 'General Section',
    text: 'General', // Placeholder for translation
    items: [
      {
        id: 'profile',
        icon: 'Profile',
        label: 'Profile', // Placeholder for translation
        link: '/dashboard/profile',
      },
      {
        id: 'password',
        icon: 'Password',
        label: 'Password', // Placeholder for translation
        link: '/dashboard/password/update',
      },
      {
        id: 'admin_panel',
        icon: 'Settings',
        label: 'Admin Panel',
        link: '/admin',
        requireRole: USER_ROLE.ADMIN,
      },
    ],
  },
  {
    id: 'Others Section',
    text: 'Others', // Placeholder for translation
    items: [
      {
        id: 'contact-us',
        icon: 'ContactUs',
        label: 'Contact Us',
        link: '/dashboard/contact-us',
      },
      {
        id: 'dark_mode',
        icon: 'DarkMode',
        label: 'Dark mode', // Placeholder for translation
        isButton: true,
      },
      {
        id: 'logout',
        icon: 'Logout',
        label: 'Log out', // Placeholder for translation
        isButton: true,
      },
    ],
  },
];

export const AdminPanelSidebarDataSource: SidebarItemCategory[] = [
  {
    id: 'Musajjel Management Section',
    text: 'Musajjel Management',
    items: [
      {
        id: 'overview',
        icon: 'Analytics',
        label: 'Overview',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.OVERVIEW}`,
      },
    ],
  },
  {
    id: 'Content Section',
    text: 'Content',
    items: [
      {
        id: 'categories',
        icon: 'Category',
        label: 'Categories',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.CATEGORY}`,
      },
      {
        id: 'comments',
        icon: 'Comment',
        label: 'Comments',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.COMMENT}`,
        disabled: true,
      },
      {
        id: 'audio_mark',
        icon: 'Clock',
        label: 'Audio Mark',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.AUDIO_MARK}`,
        disabled: true,
      },
    ],
  },
  {
    id: 'Users Section',
    text: 'Users',
    items: [
      {
        id: 'podcasters',
        icon: 'Profile',
        label: 'Podcasters',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.PODCASTER}`,
      },
      {
        id: 'administrators',
        icon: 'AddUser',
        label: 'Administrators',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.MANAGE_ADMIN}`,
        disabled: true,
      },
    ],
  },
  {
    id: 'Payments Section',
    text: 'Payments',
    items: [
      {
        id: 'transaction_history',
        icon: 'Payout',
        label: 'Transaction History',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.TRANSACTION_HISTORY}`,
      },
      {
        id: 'subscription_plans',
        icon: 'SubscriptionPlan',
        label: 'Subscription Plans',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.MANAGE_PLAN}`,
      },
      {
        id: 'promo_codes',
        icon: 'PromoCode',
        label: 'Promo Codes',
        link: `${ADMIN_APP_ROUTES.ROOT}/${ADMIN_APP_ROUTES.PROMOCODE}`,
      },
    ],
  },
  {
    id: 'General Section',
    text: 'General',
    items: [
      {
        id: 'back_to_dashboard',
        icon: 'Settings',
        label: 'Back to Dashboard',
        link: `/${DASHBOARD_APP_ROUTES.DASHBOARD}`,
      },
    ],
  },
  {
    id: 'Others Section',
    text: 'Others',
    items: [
      {
        id: 'dark_mode',
        icon: 'DarkMode',
        label: 'Dark mode',
        isButton: true,
      },
      {
        id: 'logout',
        icon: 'Logout',
        label: 'Log out',
        isButton: true,
      },
    ],
  },
];
