import React from 'react';

const ShareIcon = () => (
  <svg width='30' height='30' viewBox='0 0 34 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M28.9212 38C27.5131 38 26.3181 37.5109 25.3364 36.5328C24.356 35.5532 23.8659 34.364 23.8659 32.965C23.8659 32.7539 23.9594 32.2212 24.1464 31.3669L8.85275 22.3461C8.39375 22.8725 7.83204 23.2856 7.16762 23.5853C6.50321 23.8851 5.78992 24.035 5.02775 24.035C3.63092 24.035 2.44375 23.541 1.46625 22.553C0.48875 21.565 0 20.3807 0 19C0 17.6193 0.48875 16.435 1.46625 15.447C2.44375 14.459 3.63092 13.965 5.02775 13.965C5.7885 13.965 6.50179 14.1149 7.16762 14.4147C7.83346 14.7144 8.39517 15.1282 8.85275 15.656L24.1485 6.67322C24.0493 6.40018 23.9778 6.12926 23.9339 5.86044C23.8885 5.59022 23.8659 5.31437 23.8659 5.03289C23.8659 3.63533 24.3596 2.44678 25.347 1.46722C26.3344 0.489074 27.5329 0 28.9425 0C30.3521 0 31.5477 0.490481 32.5295 1.47144C33.5112 2.45241 34.0014 3.64307 34 5.04344C33.9986 6.44381 33.5063 7.63167 32.5231 8.607C31.54 9.58233 30.3429 10.0693 28.9319 10.0679C28.164 10.0679 27.4557 9.91167 26.8069 9.59922C26.158 9.28678 25.6055 8.86667 25.1494 8.33889L9.8515 17.3597C9.95067 17.6327 10.0222 17.9043 10.0661 18.1746C10.1115 18.4434 10.1341 18.7185 10.1341 19C10.1341 19.2815 10.1115 19.5566 10.0661 19.8254C10.0208 20.0943 9.94996 20.3659 9.85362 20.6403L25.1494 29.6611C25.607 29.1333 26.1595 28.7132 26.8069 28.4008C27.4557 28.0883 28.164 27.9321 28.9319 27.9321C30.34 27.9321 31.5371 28.4219 32.5231 29.4014C33.5077 30.3838 34 31.5752 34 32.9756C34 34.3759 33.5063 35.5638 32.5189 36.5391C31.5315 37.5144 30.3308 38.0014 28.9212 38Z'
      fill='white'
    />
  </svg>
);

export default ShareIcon;
